import Vue from 'vue';

export default {
    /* find(parametros) {
        return Vue.axios.get("conceptos-nominas-formulas/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("conceptos-nominas-formulas/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }, */
    update(parametros) {
        return Vue.axios.put("conceptos-nominas-formulas", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("conceptos-nominas-formulas/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("conceptos-nominas-formulas", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    }
}